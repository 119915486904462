import { mdiVectorPolygon, mdiVectorPolyline, mdiMapMarkerOutline, mdiNavigationVariantOutline, mdiCropSquare } from '@mdi/js';
const cartographyConfig= {
    MEASURING_UNITS: {
        POLYGON:[
            { label: 'square feet', value: 'ft2', nickName: "sq ft" },
            { label: 'square yards', value: 'yd2', nickName: "sq yd" },
            { label: 'acres', value: 'a', nickName: "acres" },
            { label: 'square inches', value: 'in2', nickName: "sq in" },
            { label: 'square meters', value: 'm2', nickName: "sq meter", default: true },
            { label: 'hectares', value: 'ha', nickName: "hect" },
            { label: 'square kilometers', value: 'km2', nickName: "sq km" },
        ],
        POLYLINE: [
            { label: 'meter', value: 'm', nickName: "meter", default: true },
            { label: 'kilometers', value: 'km', nickName: "km" },
            { label: 'centimeters', value: 'cm', nickName: "cm" },
            { label: 'millimeters', value: 'mm', nickName: "mm" },
            { label: 'miles', value: 'mi', nickName: "miles" },
            { label: 'seamiles', value: 'sm', nickName: "sm" },
            { label: 'feet', value: 'ft', nickName: "ft" },
            { label: 'inches', value: 'in', nickName: "in" },
            { label: 'yards', value: 'yd', nickName: "yd" },
        ],
        SPOT: [
            { label: "", value: '', disabled: 'disabled', default: true },
        ]
    },
    LABEL_FONTS:[
        {label:"0.5pts" , value:0.5},  
        {label:"0.8pts" , value:0.8},
        {label:"1pts" , value:1},
        {label:"1.3pts" , value:1.3},
        {label:"1.5pts" , value:1.5},
        {label:"1.8pts" , value:1.8},
        {label:"2pts" , value:2},
    ],
    CUSTOM_MEAS_UNITS: [
        { label: "Unit", value: "unit", nickName: "unit" },
        { label: "Each", value: "each", nickName: "each" }
    ],
    CONVERSION_VALUES: {
        KM: 0.001,
        CM: 100,
        MM: 1000,
        MI: 0.000621371,
        SM: 0.000539957,
        FT: 3.28084,
        IN: 39.3701,
        YD: 1.09361,
        M: 1,
        A: 0.000247105, //acres
        IN2: 1550, //square inches
        YD2: 1.19599, //square yards
        FT2: 10.7639, //square ft
        KM2: 1e-6, //square km
        HA: 1e-4,//ha
        M2: 1, //sqaure meter
        UNIT: 1,
        EACH: 1
    },
    MEDIA_TYPES: [{ ext: "mov", type: "video" }, { ext: "mp4", type: "video" }, { ext: "jpg", type: "img" }, { ext: "jpeg", type: "img" }, { ext: "png", type: "img" }],
    RATING: [
        { label: '1 (Worst)', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5 (Excellent)', value: 5 }
    ],
    SHAPES: { polygon: { label: "Area", icon: mdiVectorPolygon }, polyline: { label: "Line", icon: mdiVectorPolyline }, spot: { label: "Spot", icon: mdiMapMarkerOutline } },
    DRAWING_SIDEBAR: {
        currentLocation: { icon: mdiNavigationVariantOutline, tooltipId: 'mapRecenterTooltip', name: 'Current Location', position: 'right' },
        colorPicker: { icon: null, tooltipId: 'colorPickerTooltip', name: 'Stroke Color', position: 'right' },
        fillColor: { icon: mdiCropSquare, tooltipId: 'colorFillTooltip', name: 'Fill Color', position: 'right' }
    },
    ALPHABETS: 'abcdefghijklmnopqrstuvwxyz',
    MAP_OPTION: [
        {label:"Standard" , value:"standard"},  
        {label:"Satellite" , value:"satellite"},
        {label:"Hybrid" , value:"hybrid"},
        {label:"Terrain" , value:"terrain"},
    ],
    UPLOADING_STATUS: { 1: "Ready to upload", 2: "Uploading...", 3: "Uploaded", 4: "Uploading failed!" }
}

export default cartographyConfig