
const CustomSpinner = ({ customStyle }) => {
    return(
    <div style={{ 
        position: "absolute",
        width: "100%",
        height: "100vh",
        background: "rgb(255 255 255 / 34%)",
        zIndex: 999999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        ...customStyle
     }}>
        {/* <Spinner type='grow' size='lg' color="primary" style={{ padding: 30 }}/> */}
        <img src={require('../../assets/images/logo/v1.gif').default} width="500"/>
        <h4 className='text-primary'><b>Please Wait...</b></h4>
    </div>
    )
}
  
export default CustomSpinner
  