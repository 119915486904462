import { Fragment } from "react"
import { Check, X } from "react-feather"
import Avatar from "../@core/components/avatar"
const MEDIA_TYPES = [{ext:"mov",type:"video"},{ext:"mp4",type:"video"},{ext:"jpg",type:"img"},{ext:"jpeg",type:"img"},{ext:"jfif",type:"img"},{ext:"png",type:"img"}]
import { convertDistance, getCenter, getDistance } from "geolib";
import themeConfig from "../configs/themeConfig";
import cartographyConfig from '../configs/cartographyConfig'
import moment from 'moment'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { Spinner } from "reactstrap";
const MySwal = withReactContent(Swal)

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

export const isArrEmpty = arr => arr.length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export const customFormatDate = (value, se = "start") => {
  const periods = { "start": ' 00:00:00', 'end': " 23:59:59" };
  return moment(value).format(themeConfig.formats.date) + periods[se];
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: '2-digit', day: '2-digit', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const mySQLDateFormat = (value, se) => {
  const periods = { "start": ' 00:00:00', 'end': " 23:59:59" };
  if (!value) return value
  if (Array.isArray(value)) {
    let dateRange = []
    dateRange[0] = moment(value[0]).format("YYYY-MM-DD") + periods["start"];
    dateRange[1] = moment(value[1]).format("YYYY-MM-DD") + periods["end"];
    return dateRange;
  } else {
    return moment(value).format("YYYY-MM-DD") + periods[se]
  }
}
// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'member') return '/'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#bf2d2d', // for selected option bg-color
    neutral10: '#bf2d2d', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const ToastContainer = ({ title, message, type = 'success' }) => (
  <Fragment>
    <div className='toastify-header'>
      <div className='title-wrapper'>
        <Avatar size='sm' color={type} icon={type === 'success' ? <Check size={12} /> : <X size={12} />} />
        <h6 className='toast-title'>{title}!</h6>
      </div>
      {/* <small className='text-muted'>11 Min Ago</small> */}
    </div>
    <div className='toastify-body'>
      <span role='img' aria-label='toast-text'>
        {message}
      </span>
    </div>
  </Fragment>
)

export const ErrorToast = ({ title, message }) => (
  <Fragment>
    <div className='toastify-header'>
      <div className='title-wrapper'>
        <Avatar size='sm' color='success' icon={<Close size={12} />} />
        <h6 className='toast-title'>{title}!</h6>
      </div>
      {/* <small className='text-muted'>11 Min Ago</small> */}
    </div>
    <div className='toastify-body'>
      <span role='img' aria-label='toast-text'>
        {message}
      </span>
    </div>
  </Fragment>
)


export const UcFirst = (str) => {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}

export const randomColor = () => {
  const stateNum = Math.floor(Math.random() * 5),
    states = ['light-success', 'light-warning', 'light-info', 'light-primary', 'light-secondary'],
    color = states[stateNum]
  return color
}

export const fileType = uri => {
  try {
    let ext = uri.split('.').pop().toString().toLowerCase();
    let _type =MEDIA_TYPES.find(t => {return ext.includes(t.ext)})
    _type= _type ? _type : {type:"unsupported"};
    return _type.type;
  } catch (error) {
    console.log(error.message);
  }
}

const convertArea = (area, unit) => {
  return cartographyConfig.CONVERSION_VALUES[unit] * area || area
}

export const unitConversion = (area, unit, shape, options = { withTranslate: false, unitName: 'short' }) => {
  let { withTranslate, unitName } = options
  try {
    unitName = unitName === 'full' ? 'label' : 'nickName';
    area = convertArea(parseFloat(area), unit.toUpperCase())
    area = Math.round(parseFloat(area) * 10) / 10
  } catch (error) {
    // console.log("error")  
  } finally {
    let areaUnit = themeConfig.meaurementVars.find(el => el.value === unit)?.[unitName]
    areaUnit = typeof areaUnit == "undefined" ? "" : areaUnit;
    return withTranslate ? `${area} ${areaUnit}` : area
  }
}

export const conversionMetric = (val, unit) => {
  if(cartographyConfig.CUSTOM_MEAS_UNITS.filter(el=>el.value===unit)?.length>0) return val
  let oneUnit = 1 / cartographyConfig.CONVERSION_VALUES[unit.toUpperCase()] || 1
  return val * oneUnit || val
}

export const showSimpleAlert = (title, message) => {
  MySwal.fire({
    title: title,
    text: message,
    customClass: {
      confirmButton: 'btn btn-primary'
    },
    showClass: {
      popup: 'animate__animated animate__fadeIn'
    },
    buttonsStyling: false
  })
}

export const shortLatLng = coordinates => coordinates.map(c => {return {lat: c.latitude, lng: c.longitude}})

export const longLatLng = coordinates => coordinates.map(c => {return {latitude: c.lat, longitude: c.lng}})

export const numerSuffix = (i) => {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}

export const handleConfirmCancel = ({ title, text, buttonText }) => {
  return MySwal.fire({
    title: title,
    text: text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: buttonText,
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger ml-1'
    },
    buttonsStyling: false
  }).then(function (result) {
    return result
  })
}

export const toLetters = num => {
  let mod = num % 26;
  let pow = num / 26 | 0;
  let out = mod ? String.fromCharCode(64 + mod) : (pow--, 'Z');
  return pow ? toLetters(pow) + out : out;
}

export const LoadingButton = () => (
  <><Spinner color='white' size='sm' />
      <span className='ml-50'>Loading...</span></>
)

export const deepCopy = arr => {
  let newArr = []
  try {
    newArr = JSON.parse(JSON.stringify(arr))
  } catch (err) {
    console.log(err)
  }
  return newArr
}

export const setPolygonBounds = ({zone_coordinates, coordinate_holes}) => {
    let paths = [deepCopy(zone_coordinates)]
    let holes= deepCopy(coordinate_holes)
    holes?.map(hole => {
        paths.push(hole.reverse())
    })
    return paths
}

export const arrayChunks = (arr, numOfChunk) => {
  return arr.reduce((c, v, i, a) => {
      if (i + numOfChunk <= a.length) c.push(a.slice(i, i + numOfChunk));
      return c;
  }, [])
}

export const removeTransparency = color => {
  try{
    let newColor = color.search("#") == 0 ? hexToRgbShort(color, 1) : color
    let _c = newColor.replace(/[a-z()]/g, ''); _c = _c.split(",")
    return `rgba(${_c[0]},${_c[1]},${_c[2]},1)`
  }catch (error) {
    return color
  }
}

export const getMediaURI = uri => {
  return uri?.indexOf('firebasestorage') > -1 ? uri : `${themeConfig.app.cdnUrl}/${uri}`
}