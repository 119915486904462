// ** Initial State
const initialState = {
    zoneIds:[],
    isFiltered: false
  }
  
  const projectReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_ZONES':
        return {
          ...state,
          zoneIds:action.zoneIds,
        }
      case 'SET_ZONE_IS_FILTERED': 
        return {
          ...state,
          isFiltered:action.payload 
        }
      default:
        return state
    }
  }
  
  export default projectReducer