// **  Initial State
const initialState = {
  organizationData: {},
};

const saveOrganizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ORGANIZATION":
      return {
        ...state,
        organizationData: action.data,
      };
    default:
      return state;
  }
};

export default saveOrganizationReducer;
