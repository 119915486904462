// ** Redux Imports
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
import project from "../../views/projects/store/reducer";
import cartography from './cartography'
import saveOrganization from "../reducers/saveOrganization/index";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["navbar","cartography","organization"],
};

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  project,
  cartography,
  organization: saveOrganization,
});

export default persistReducer(persistConfig, rootReducer);
